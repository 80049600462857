<template>
	<div id="tutoredin-login">
		<div class="login-left">
			<div class="bg">
				<div class="title">Inspiration, Innovation and Discovery</div>
				<div class="desc">Any successful education starts with great tutors. Together with us you will have a reliable and convenient way to have deeper knowledge of English that will be especially useful for you when climbing the learning ladder.</div>
			</div>
		</div>
		<div class="login-right">
			<div class="header">
				<SetLocales type="link" color="gray" hover-color="#04d2c8"></SetLocales>
			</div>
			<div class="login-container">
				<div class="logo"><font>Tutoredin</font></div>
				<el-form ref="loginForm" :model="form" :rules="rules" label-width="0px" @keyup.enter="onLoginSubmit" style="width: 320px;">
					<el-form-item prop="username">
						<el-input v-model="form.username" :placeholder="$t('auth.enter-mobile-number-or-email-address')" clearable></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input v-model="form.password" :placeholder="$t('auth.enter-your-login-password')" show-password></el-input>
					</el-form-item>
					<el-form-item>
						<div class="agree-checkbox" v-if="!isTutoredin">
							<el-checkbox v-model="form.agree"></el-checkbox>
							<div>{{ $t('auth.read-and-agree') }} <el-link type="primary" @click="$refs.user_agreement.open()">{{ $t('Terms of use') }}</el-link> {{ $t('auth.and') }} <el-link type="primary" @click="$refs.privacy_agreement.open()">{{ $t('Privacy Agreement') }}</el-link> </div>
						</div>
						<el-button type="primary" :disabled="!form.agree" style="width: 100%;" @click="onLoginSubmit" auto-insert-space="true">{{ $t('router.login') }}</el-button>
						<div class="register-fogot">
							<el-link type="primary" @click="$router.push('/register')">{{ $t('auth.register-now') }}</el-link>
							<el-link type="danger" @click="$router.push('/forgot')">{{ $t('forgot.forgot-password') }}?</el-link>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div class="footer" >
				<el-link v-if="gongan" href="https://beian.mps.gov.cn/#/query/webSearch?code=11010102007105" rel="noreferrer" target="_blank">
					<!-- <el-image style="width: 20px; height: auto" :src="require('@/assets/gongan.png')" fit="fill"></el-image>  -->
					<span>{{ gongan }}</span>
				</el-link>
				<el-link v-if="beian" href="https://beian.miit.gov.cn/" target="_blank">{{ beian }}</el-link>
				<!-- <el-link v-if="false" @click="$router.push('/faqs')">{{ $t('Faqs') }}</el-link> -->
			</div>
		</div>
		<UserAgreement ref="user_agreement" />
		<PrivacyAgreement ref="privacy_agreement" />
	</div>
</template>
<script>
import { mapActions } from 'vuex'
import { checkToken } from '@/utils'
import UserAgreement from '@/components/UserAgreement'
import PrivacyAgreement from '@/components/PrivacyAgreement'
import RegexTool from '@/utils/regex'
import SetLocales from '@/components/SetLocales'
import Config from '@/utils/config'

export default {
	components: {
		UserAgreement,
		PrivacyAgreement,
		SetLocales
	},
  	data() {
		return {
			redirect: '/dashboard',
			form: {
				username: '',
				password: '',
				agree: true
			},
			rules: {
				username: [
					{
						required: true,
						message: this.$t('auth.Login-user-name-is-required'),
						trigger: 'blur'
					},
					{
						pattern: RegexTool.username,
						message: this.$t('auth.Please-enter-mobile-and-emial'),
						trigger: 'blur'
					}
				],
				password: [
					{
						required: true,
						message: this.$t('auth.Login-password-must-be-filled'),
						trigger: 'blur'
					},
					{
						min: 6,
						max: 16,
						message: this.$t('auth.Login-password-characters'),
						trigger: 'blur'
					}
				]
			},
		}
	},
	computed: {
		isTutoredin() {
			return Config.isTutoredin();
		},
		beian() {
			return Config.website('beian');
		},
		gongan() {
			return Config.website('gongan');
		}
	},
	created() {
		let redirect = this.$route.query.redirect
		if(redirect) {
			this.redirect = redirect == '/' ? '/dashboard' : redirect;
		}
		if(checkToken() === true) {
			this.$router.push({path: this.redirect})
		}
	},
	methods: {
		...mapActions(['Login', 'GetInfo']),

		onLoginSubmit() {
			let that = this
			this.$refs.loginForm.validate((valid) => {
				if(valid) {
					if(!this.isTutoredin && this.form.agree != 1) {
						this.$message.error(this.$t('auth.Must-click-agree-checkbox'))
						return false;
					}
					this.Login(this.form).then(res => {
						this.$message.success(this.$t('auth.login-successful'));
						this.$store.dispatch('GetUserInfo')
						setTimeout(function(){
							that.$router.push({path: that.redirect})
						}, 2500)
					})
				} else {
					this.$message.error(this.$t('auth.login-error'))
					return ;
				}
			})	
		},
	},
}
</script>
<style lang="less">
#tutoredin-login {
	-moz-box-align: center;
	-webkit-align-items: center;
	align-items: center;
	display: -webkit-flex;
	display: -moz-box;
	display: flex;
	height: 100vh;
	min-height: 700px;
	width: 100%;

	.login-left {
		-webkit-flex: 1 1;
		flex: 1 1;

		.bg{
			background-image: url("~@/assets/bg.123456.jpg");
			background-position: 100%;
			background-repeat: no-repeat;
			background-size: cover;
			height: 100vh;
			min-height: 700px;

			display: flex;
			flex-direction: column;
			justify-content: center;
			text-align: center;
			padding: 0 10%;

			.title {
				font-size: 3.5rem;
				color: #FFF;
				line-height: 1.1;
				margin-bottom: 20px;
			}

			.desc {
				font-size: 1rem;
				color: #FFF;
				line-height: 1.65;
			}
		}
	}

	.login-right {
		.header {
			position: absolute;
			right: 15px;
			top: 15px;
		}

		.login-container {
			text-align: left;
			padding-left: 200px;
			padding-right: 200px;

			.logo {
				line-height: 2;

				font {
					font-size: 42px;
					font-weight: 500;
					color: var(--el-color-primary);
				}
			}

			.agree-checkbox {
				display: flex;
				div {
					margin-left: 5px;
				}

				.el-link--inner {
					line-height: 1;
				}
			}

			.register-fogot {
				display: flex;
				margin-top: 12px;
				    justify-content: space-between;

				.el-link--inner {
					line-height: 1;
				}
			}
		}

		.footer {
			position: fixed;
			bottom: 10px;
			right: 10px;

			& > a {
				margin: 0 5px;
			}
		}
	}
}

</style>